import React from "react"
import styled from "styled-components"
import "normalize.css"
import Nav from "./Nav.js"
import Footer from "./Footer"
import GlobalStyles from "../styles/GlobalStyles"
import Typography from "../styles/Typography"

const SiteBorderStyles = styled.div`
  max-width: 1000px;
  margin: 12rem auto 4rem auto;
  margin-top: clamp(2rem, 10vw, 12rem);
  background: black;
  background-size: 1500px;
  padding: 5px;
  padding: clamp(5px, 1vw, 25px);
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.044);
  border: 5px solid white;
  @media (max-width: 1100px) {
    max-width: 1100px;
    background-size: 0px;
    background: none;
    margin: 0px;
    padding: 0px;
    border: none;
  }
`

const ContentStyles = styled.div`
  background: var(--bg-content);
  padding: 2rem;
  @media (max-width: 1100px) {
    margin: 0;
    padding: 0;
  }
`

export default function Layout({ children }) {
  return (
    <>
      <GlobalStyles />
      <Typography />
      <SiteBorderStyles>
        <ContentStyles>
          <Nav />
          {children}
          <Footer />
        </ContentStyles>
      </SiteBorderStyles>
    </>
  )
}
