import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
export default function Footer() {
  const FooterStyle = styled.div`
    footer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 2rem;
    }
    @media (max-width: 800px) {
      p,
      a {
        font-size: var(--heading-3);
      }
    }
  `
  return (
    <FooterStyle>
      <footer role="contentinfo">
        <p className="center">
          &copy; Run Rabbit Run {new Date().getFullYear()}
        </p>
        <p>
          <Link to="/contact">Contact Me</Link>
        </p>
      </footer>
    </FooterStyle>
  )
}
