import React, { useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

const MenuStyle = styled.div`
  .nav {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    padding: 1.5rem;
  }
  .button-div {
    display: flex;
  }
  .links {
    font-size: var(--heading-2);
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    gap: 0.5rem;
  }
  .link-button {
    margin-right: 2rem;
    box-shadow: none;
    font-size: var(--heading-1);
    background: none !important;
    border: none;
    padding: 0 !important;
    color: var(--blue);
    text-decoration: underline var(--red);
    cursor: pointer;
    align-self: start;
  }
  button {
    background-color: var(--blue);
    box-shadow: none;
  }
  .button {
    /* box-shadow: none; */
    display: flex;
    align-items: center;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-width: 5px;
    border-radius: 0.25rem;
    color: #b2f5ea;
    border-color: #4fd1c5;
    &:hover {
      border-color: blue;
      color: green;
    }
  }
  .button-svg {
    fill: white;
    height: 2rem;
    width: 2.5rem;
  }
  .block {
    display: block;
  }
  .hidden {
    display: none;
  }
  .grows {
    /* display: flex; */
    text-align: left;
    width: 100%;
    align-content: left;
    flex-grow: 1;
  }

  @media (min-width: 601px) {
    .button-div {
      display: none;
    }
  }
`
export default function MobileMenu() {
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <MenuStyle>
      <nav className="nav">
        <div className="button-div">
          <button
            onClick={() => toggleExpansion(!isExpanded)}
            className="button"
          >
            <svg
              className="button-svg"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div className={`${isExpanded ? `block` : `hidden`} grows`}>
          <div className="links">
            <button
              className="link-button"
              onClick={() => {
                navigate("/")
                toggleExpansion(!isExpanded)
              }}
            >
              Musings
            </button>
            <button
              className="link-button"
              onClick={() => {
                navigate("/pi-labs")
                toggleExpansion(!isExpanded)
              }}
            >
              Myanmar
            </button>
            <button
              className="link-button"
              onClick={() => {
                navigate("/podcasting")
                toggleExpansion(!isExpanded)
              }}
            >
              Podcasting
            </button>
            <button
              className="link-button"
              onClick={() => {
                navigate("/coding")
                toggleExpansion(!isExpanded)
              }}
            >
              Coding
            </button>
            <button
              className="link-button"
              onClick={() => {
                navigate("/contact")
                toggleExpansion(!isExpanded)
              }}
            >
              Contact
            </button>
          </div>
        </div>
      </nav>
    </MenuStyle>
  )
}
