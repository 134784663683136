// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/styles/normalize.css"
// custom CSS styles
// import "./src/styles/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"
import React from "react"
import Layout from "./src/components/Layout"
// import { OrderProvider } from './src/components/OrderContext';

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>
}
