import React from "react"
import { Link } from "gatsby"
import Logo from "../assets/images/logo.svg"
import styled from "styled-components"

const LgMenuStyle = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`

export default function LargeMenu() {
  return (
    <LgMenuStyle>
      <div className="lg-menu">
        <ul>
          <li className="logo-item">
            <Link to="/">
              <img className="rotate-image logo" src={Logo} alt="logo" />
            </Link>
          </li>
          <li>
            <Link to="/">Musings</Link>
          </li>
          <li>
            <Link to="/pi-labs/">Myanmar</Link>
          </li>
          <li>
            <Link to="/podcasting">Podcasting</Link>
          </li>
          <li>
            <Link to="/coding">Coding</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
    </LgMenuStyle>
  )
}
