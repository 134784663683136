import { createGlobalStyle } from "styled-components"
import bg from "../assets/images/bg.svg"
import stripes from "../assets/images/stripes.svg"

const GlobalStyles = createGlobalStyle`
  :root {
    --red:  #CC2936;
    --black: #030301;
    --blue: #095197;
    --yellow: #FFE66D;
    --green: #248232;
    --grey: #EEE5E9;
    --bg-color: var(--grey);
    --bg-content: var(--grey);
    --nav-color: #B0C6CE;
    --maxWidth-none: "none";
    --maxWidth-xs: 20rem;
    --maxWidth-sm: 24rem;
    --maxWidth-md: 28rem;
    --maxWidth-lg: 32rem;
    --maxWidth-xl: 36rem;
    --maxWidth-2xl: 42rem;
    --maxWidth-3xl: 48rem;
    --maxWidth-4xl: 56rem;
    --maxWidth-full: "100%";
    --maxWidth-wrapper: var(--maxWidth-2xl);
    --spacing-px: "1px";
    --spacing-0: 0;
    --spacing-1: 0.25rem;
    --spacing-2: 0.5rem;
    --spacing-3: 0.75rem;
    --spacing-4: 1rem;
    --spacing-5: 1.25rem;
    --spacing-6: 1.5rem;
    --spacing-8: 2rem;
    --spacing-10: 2.5rem;
    --spacing-12: 3rem;
    --spacing-16: 4rem;
    --spacing-20: 5rem;
    --spacing-24: 6rem;
    --spacing-32: 8rem;
  }
  html {
    background-image: url(${bg});
    background-size: 100px;
    background-attachment: fixed;
    font-size: 10px;
    background-color: var(--bg-color);
    --baseFontSize: 1rem;
    --baseNavSize: 0.64rem;
    --smallFontSize: 0.8rem;
    --smallestFontSize: 0.512rem;
    --xtra-big-ass-heading: 3.052rem;
    --xtra-heading: 2.441rem;
    --heading-1: 1.953rem;
    --heading-2: 1.563rem;
    --heading-3: 1.25rem;
    --heading-4: var(--baseFontSize);
    --heading-5: var(--smallFontSize);
    --heading-6: 0.64rem;
  }

  body {
    font-size: 2rem;
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }
  

  button {
    display: inline-block;
    /* width: 30%; */
    background: var(--red);
    color: white;
    border: 0;
    padding: 0.6rem 1rem;
    border-radius: 2px;
    cursor: pointer;
    --cast: 2px;
    box-shadow: var(--cast) var(--cast) 0 var(--grey);
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,0.2);
    transition: all 0.2s;
    &:hover {
      background: var(--blue);
      color: var(--red);
    
      --cast: 4px;
    }
  }
  .button {
    display: inline-block;
    /* width: 30%; */
    background: var(--red);
    color: white;
    border: 0;
    padding: 0.6rem 1rem;
    border-radius: 2px;
    cursor: pointer;
    --cast: 2px;
    box-shadow: var(--cast) var(--cast) 0 var(--grey);
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,0.2);
    transition: all 0.2s;
    &:hover {
      background: var(--blue);
      color: var(--red);
      --cast: 4px;
    }
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--red) var(--white);
  }
  body::-webkit-scrollbar-track {
    background: white;
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--red) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }

  hr {
    border: 0;
    height: 8px;
    background-image: url(${stripes});
    background-size: 1500px;
  }
  .center {
    text-align: center;
  }
  .centerDiv {
      display: flex;
      /* justify-content: center; */
      align-content: center;
      flex-direction: row;
      /* text-align: center; */
      align-items: center;
      margin: 5px;
      p {
        padding-left: 10px;
      }
    }
  img {
    max-width: 100%;
  }
  .indented {
    text-indent: 4rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .tilt {
    transform: rotate(-2deg);
    position: relative;
    display: inline-block;
  }
  @media (max-width: 1100px) {
    html {
      background-color: var(--bg-content); 
      background-image: none;
    }
  }
  
  
`

export default GlobalStyles
