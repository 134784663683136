// import React from "react"
// import { Link } from "gatsby"
// import styled from "styled-components"
// import Logo from "../assets/images/logo.svg"
// import MobileMenu from "../components/MobileMenu"
import LargeMenu from "./LargeMenu"
import React from "react"
// import { Link, navigate } from "gatsby"
// import PropTypes from "prop-types"
import styled from "styled-components"
import MobileMenu from "./MobileMenu"

const NavStyles = styled.div`
  display: grid;
  margin-bottom: 2rem;
  background: var(--nav-color);
  grid-template-rows: auto 1fr;
  ul {
    margin: 0;
    padding: 0;
    text-align: center;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 2rem;
    align-items: center;
  }
  li {
    --rotate: -2deg;
    transform: rotate(var(--rotate));
    order: 1;
    &:nth-child(2) {
      --rotate: -2.5deg;
    }
    &:nth-child(3) {
      --rotate: 2.5deg;
    }
    &:nth-child(4) {
      --rotate: -2.5deg;
    }
    &:nth-child(5) {
      --rotate: 2.5deg;
    }
    &:nth-child(6) {
      --rotate: -2.5deg;
    }
  }
  a {
    color: var(--blue);
    font-size: 3rem;
    text-decoration: none;
    display: block;
    &:hover {
      color: var(--red);
      transform: scale(1.2);
    }
    @media (max-width: 800px) {
      font-size: 1.5rem;
    }
  }
  .logo-item {
    transition: all 0.2s ease-in-out;
  }
  .logo {
    transform: translateY(-50%);
    max-width: 60%;
  }
  .rotate-image {
    animation: rotation 10s linear infinite;
  }
  //
  .title-banner {
    text-align: center;
    font-size: var(--heading-1);
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-359deg);
    }
  }
  @media (max-width: 600px) {
    display: flex;
    /* flex-direction: row-reverse; */
    margin-bottom: 1rem;
    justify-content: space-between;
    border-bottom: 2px solid var(--blue);
    /* padding-bottom: 1rem; */
    ul {
      display: grid;
      gap: 0;
      grid-template-columns: 40% 60%;
      justify-items: center;
      align-items: center;
    }
    ul li:nth-child(1) {
      justify-content: center;
      grid-column: 1;
      grid-row: 1 / 6;
    }
    ul li:nth-child(2) {
      grid-column: 2;
      grid-row: 1;
    }
    ul li:nth-child(3) {
      grid-column: 2;
      grid-row: 2;
    }
    ul li:nth-child(4) {
      grid-column: 2;
      grid-row: 3;
    }
    ul li:nth-child(5) {
      grid-column: 2;
      grid-row: 4;
    }
    ul li:nth-child(6) {
      grid-column: 2;
      grid-row: 5;
    }
    .logo-item {
      order: 0;
    }
    .logo {
      transform: none;
      max-width: 50%;
    }
  }
`
//
const BoxStyle = styled.div`
  body {
    padding: 2rem;
    background: #f3f3f3;
  }
  text-align: center;
  transform: translateY(25%);
  .sketchy {
    padding: 2rem 4rem;
    display: inline-block;
    border: 3px solid #333333;
    font-size: var(--heading-1);
    border-radius: 2% 6% 5% 4% / 1% 1% 2% 4%;
    text-transform: uppercase;
    letter-spacing: 0.3ch;
    background: var(--bg-color);
    position: relative;
    &::before {
      content: "";
      border: 2px solid #353535;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0) scale(1.015) rotate(0.5deg);
      border-radius: 1% 1% 2% 4% / 2% 6% 5% 4%;
    }
  }
  @media (max-width: 600px) {
    transform: translateY(5%);
    text-align: right;
    margin: 1rem 0 0 1rem;
    .sketchy {
      font-size: var(--heading-3);
      padding: 1rem 1rem;
    }
  }
`

export default function Nav() {
  return (
    <NavStyles>
      <BoxStyle>
        <div className="sketchy">Just Josh</div>
      </BoxStyle>

      <MobileMenu />

      <LargeMenu />
    </NavStyles>
  )
}
